import { cloneDeep, isArray, merge, mergeWith } from 'lodash';
import uuid from 'uuid/v4';
import shopnow from './shopnow';
import advancedExchanges from './advancedExchanges';
import collections from './collections';
import products from './products';
import reasons from './reasons';
import fees from './fees';
import totals, { updateTotals } from './totals';
import returnData from './return';
import workflows from './workflows';
import firstMile from './firstMile';
import giftReceipt from './giftReceipt';
import tracking from './tracking';
import currencies from './currencies';
import analytics from './analytics';
import axios from 'axios';
import theme from '@/schemas/theme/default.json';
import settings from '@/schemas/settings.json';
import content from '@/schemas/content';
import { constructPayload } from '@/js/newPayload';
import QrCode from '@/js/controllers/qrCode';
import { createStore } from 'vuex';
import FraudRisk from '@/js/controllers/fraudRisk';
import useStoreMethods from '@/js/mixins/store';
import { logError } from '@/js/helpers/errors';

const { setStore, getStore } = useStoreMethods();

const Store = createStore({
  modules: {
    shopnow,
    collections,
    products,
    reasons,
    fees,
    totals,
    return: returnData,
    workflows,
    firstMile,
    giftReceipt,
    advancedExchanges,
    currencies,
    analytics,
    tracking
  },
  state: {
    order: 0,
    error: null,
    hasFinished: false,
    currency: 'USD',
    states: null,
    provinces: null,
    shopContents: null,
    gift_flow: null,
    enabled_gift_receipt_flow: null,
    customizations: {},
    tabs: [],
    language: 'en',
    supportedLanguages: ['en'],
    online: true,
    uuid: null,
    cartToken: null,
    screen: { width: 0, height: 0 },
    toast: { message: '', type: 'success' },
    portal_services_configs: null,
    embeddedOrder: null,
    location: null,
    edits: {
      active: false,
      order: null,
      return: null,
      content: {},
      theme: {}
    },
    originalLineItems: null,
    singleEligibleItem: false,
    reimbursements: null,
    generate_usps_qr_codes: false,
    enable_exchange_replace_option: true,
    packing_slips_enabled: false,
    storeLocatorOrderQrCode: {},
    source: null,
    feature_flags: null,
    experiments: [],
    shopNowBonus: null,
    fraudScoreCheckedAtTimestamp: null,
    shopNowNavigation: false
  },

  mutations: {
    //update order state and save to local storage
    updateData: function (state, data) {
      var options = {
        name: data.name || false, //required
        data: data.data || false, //required
        save: data.save || false
      };

      state[options.name] = options.data;

      if (options.save) {
        setStore(options.name, options.data);
      }
    },

    //update order state and save to local storage
    updateOrder: function (state, data) {
      state['order'] = data;
      setStore('order', data);
    },

    updateCart(state, cart) {
      state['order'] = {
        ...state.order,
        cart
      };
      setStore('order', {
        ...state.order,
        cart
      });
    },

    //Open the error modal and check for any options in the data
    launchError: function (state, data) {
      //Check for options in data
      if (data.errorMessage !== undefined) {
        state.errorModal = data;
      }
    },

    //add selected product data to the modal, opens modal
    launchModal: function (state, lineitem) {
      state.modal.candidate = lineitem;
      state.modal.open();
    },
    setEmbeddedOrder(state, order) {
      state.embeddedOrder = order;
    },
    setPOSLocation(state, location) {
      state.location = location;
    },
    setLanguage(state, language) {
      state.language = language;
    },
    setError(state, payload) {
      state.error = payload;
    },
    setScreen(state, payload) {
      state.screen = payload;
    },
    setEdits(state, { type, data }) {
      state.edits[type] = data;
    },
    setOriginalLineItems(state, lineItems) {
      state.originalLineItems = cloneDeep(lineItems);
    },
    setStoreLocatorOrderQrCode(state, payload) {
      state.storeLocatorOrderQrCode = payload;
    },
    setWarrantyAllowedOutcomes(state, payload) {
      const allowed = payload['allowed'];
      const lineItemId = payload['lineItemId'];

      state.order.line_items[lineItemId].allowed = {
        ...state.order.line_items[lineItemId].allowed,
        ...allowed
      };
      state.order.line_items[lineItemId].warrantyAllowedOutcomes = allowed;
    },
    setFraudScoreCheckedAtTimestamp(state, timestamp) {
      setStore('fraudScoreCheckedAtTimestamp', timestamp);
      state.fraudScoreCheckedAtTimestamp = timestamp;
    },
    setShopNowNavigation(state, payload) {
      setStore('shopNowNavigation', payload);
      state.shopNowNavigation = payload;
    },
  },

  actions: {
    getTabs({ state, commit }) {
      return axios
        .get(`api/v1/${state.shopContents.id}/collections`)
        .then(response => {
          if (response.data.errors !== undefined) {
            return false;
          }

          commit('updateData', {
            name: 'tabs',
            data: response.data
          });

          return response.data;
        })
        .catch(status => {
          console.error(status);
          commit('setError', {
            copy: status.errors
          });
        });
    },
    // This gets run when a new order is started
    initOrder({ commit, dispatch }, order) {
      commit('setFraudScoreCheckedAtTimestamp', null);
      commit('updateOrder', order);
      commit('updateData', {
        name: 'currency',
        data: order.currency
      });

      // This helps us identify each separate return attempt
      commit('updateData', {
        name: 'uuid',
        data: uuid(),
        save: true
      });

      // Shuffle reasons if needed, they get shuffled for every separate return attempt
      dispatch('reasons/shuffle');
    },
    updateOrder({ commit }, data) {
      commit('updateOrder', data);
      return Promise.resolve('finished');
    },
    updateLineItem({ commit, state }, data) {
      commit('updateOrder', {
        ...state.order,
        line_items: {
          ...state.order.line_items,
          [data.id]: {
            ...state.order.line_items[data.id] ?? {},
            ...data
          }
        }
      });

      return state.order.line_items[data.id];
    },
    removeLineItemProperty({ commit, state }, { id, propertyToRemove }) {
      const newOrder = state.order;
      delete newOrder.line_items[id][propertyToRemove];
      commit('updateOrder', {
        ...state.order,
        ...newOrder
      });
    },
    toast({ commit }, { message = '', type = 'success' }) {
      commit('updateData', {
        name: 'toast',
        data: { message, type }
      });
    },
    hasFeature: (state) => (flagId) => {
      const flags = state.feature_flags ?? [];
      const flag = flags.find((flag) => flag.id === flagId);
      return flag?.active ?? false;
    },
    setLanguage({ commit }, language) {
      commit('setLanguage', language);

      // We need the correct lang set on <html>
      document.documentElement.setAttribute('lang', language);

      // Set language choice in localStorage so it's persisted across page reloads
      // or returns from Shop Now
      localStorage.setItem('lang', language);
    },
    async getStoreLocatorOrderQrCode({ commit, state }) {
      const response = await QrCode.generateLookupQrCode('order', state.order.id);
      const { qr_code } = response.data;
      commit('setStoreLocatorOrderQrCode', {
        url: qr_code.url,
        orderName: qr_code.lookup,
      });
    },
    async getWarrantyAllowedOutcomes({ commit, state }, data) {
      if (!data.workflowGroupId) {
        return null;
      }

      const { data: allowedOutcomes } = await axios.post(`api/v1/${state.shopContents.id}/warranty/allowed`, data);

      allowedOutcomes['lineItemId'] = data.lineItemId;
      commit('setWarrantyAllowedOutcomes', allowedOutcomes);

      return allowedOutcomes;
    },
    async check({ state,commit,getters }) {
      try {
        await FraudRisk.check(state.shop_id, {
          orderId: state.order.id,
          returnLineItems: state.return.lineItems,
          totalRefundValue: state.totals.receipt.total,
          totalRefundCurrency: state.totals.receipt.currency,
          timestamp: getters.fraudScoreCheckedAtTimestamp ?? null,
        }).then(response => {
          commit('setFraudScoreCheckedAtTimestamp', response?.data?.timestamp);
        });
      } catch (error) {
        logError(error);
      }
    },
  },

  getters: {
    content(state) {
      const selectedLanguageContent = content[state.language] || content['en'];
      const selectedLanguageCustomizations = state.customizations.content
        ? state.customizations.content[state.language]
        : {};

      return mergeWith(
        {},
        content['en'],
        selectedLanguageContent,
        selectedLanguageCustomizations,
        state.edits.content,
        (obj, src) => {
          if (isArray(obj)) {
            return src;
          }
        }
      );
    },
    theme(state) {
      return merge(theme, state.customizations.theme, state.edits.theme);
    },
    settings(state) {
      return merge(
        settings,
        state.customizations.settings,
        { currency: state.currency },
        { language: state.language },
        { differentPricedExchangesEnabled: state.diff_priced_exchanges === 'yes' },
        { giftFlowEnabled: !!state.gift_flow },
        { enabledGiftReceiptFlow: !!state.enabled_gift_receipt_flow },
        { singleItem: !!state.single_product_returns },
        { poweredByLoop: state.powered_by_loop_enabled },
        { hideAddressOnConfirmation: state.hide_address_on_confirmation },
        { enforceProductRules: state.enforce_product_rules },
        { shopNowDisabledWhenRuleApplies: state.shop_now_disabled_when_rule_applies },
        { portalServicesConfigs: state.portal_services_configs },
        { availableLookupFields: state.available_lookup_fields },
        { csatRspCustomerPercentage: state.csatRspCustomerPercentage },
        { checkoutForUpsell: state.checkout_for_upsell },
        { reimbursements: state.reimbursements },
        { generateUspsQrCodes: state.generate_usps_qr_codes },
        { enableExchangeReplaceOption: state.enable_exchange_replace_option },
        { packingSlipsActive: state.packing_slips_enabled },
        { customerPortalUrl: state.customer_portal_url },
        { requirePolicyAcceptance: state.require_policy_acceptance },
        { recommendationsEnabled: state.recommendations_enabled },
        { sustainabilityBannerEnabled: state.sustainability_banner_enabled },
        { preselectReturnMethodEnabled: state.preselect_return_method_enabled },
        { hideLabelButtonForExpiredReturns: state.hide_label_button_for_expired_returns },
        { productDescription: state.product_description },
        { enablePointOfSale: state.enable_point_of_sale },
        { shopLaterEnabled: state.shop_later_enabled },
        { smartRecommendationsEnabled: state.smart_recommendations_enabled },
        { exchangeUpsellAndRefundEnabled: state.exchange_upsell_and_refund_enabled },
        { exchangeUpsellAndRefundAllowed: state.exchange_upsell_and_refund_allowed },
        { preDiscountCredit: state.pre_discount_credit },
        { warrantiesEnabled: state.warranties_enabled },
        { isTracking: state.is_tracking },
        { enableSplitRefunds: state.enable_split_refunds },
        { orderTrackingProductRecommendationsEnabled: state.order_tracking_product_recommendations_enabled },
        { orderTrackingProductRecommendationsEngine: state.order_tracking_product_recommendations_enabled ? state.order_tracking_product_recommendations_engine : null },
        { orderTrackingProductRecommendationsDataSource: state.order_tracking_product_recommendations_enabled ? state.order_tracking_product_recommendations_data_source : null },
        { fraudTools: state.fraud_tools },
        { shopNowForAllEnabled: state.shop_now_for_all_enabled }
      );
    },
    shop(state) {
      return state.shopContents || {};
    },
    hasFeature: (state) => (flagId) => {
      const flags = state.feature_flags ?? [];
      const flag = flags.find((flag) => flag.id === flagId);
      return flag?.active ?? false;
    },
    experimentVariation: (state) => (flagId) => {
      const experiments = state.order?.experiments ?? [];
      return experiments[flagId] ?? 'Non-Participant';
    },
    hasReturnCoverage(state) {
      return state.order.return_coverage;
    },
    order(state) {
      return state.edits.order ?? state.order;
    },
    return(state) {
      return constructPayload(
        state.order.line_items,
        state.order.creditType
      );
    },
    wasCashPurchased(state) {
      return state.order?.transactions?.some(t => t.gateway === 'cash') ?? false;
    },
    fraudScoreCheckedAtTimestamp(state) {
      return state.fraudScoreCheckedAtTimestamp ?? getStore('fraudScoreCheckedAtTimestamp');
    },
    shopNowNavigation(state) {
      return state.shopNowNavigation ?? getStore('shopNowNavigation');
    },
  },
});

Store.subscribe((mutation) => {
  updateTotals(mutation, Store);
});

export default Store;
