<template>
  <main
    class="app-container"
    :class="{ 'tracking-search-container': orderTrackingEnabled && isTrackingRoute }"
  >
    <back-to-shop
      class="back-to-shop-large"
    />
    <not-found v-if="!orderTrackingEnabled || !isTrackingRoute" />
    <div
      v-else
      class="app-wrap"
    >
      <visually-hidden>
        <h1>
          {{ shop.name }}
        </h1>
      </visually-hidden>
      <app-header class="order-lookup-tracking-banner" />

      <base-card class="order-lookup-tracking">
        <h2
          class="order-lookup-tracking__heading"
          data-testid="order_lookup_tracking_heading"
        >
          {{ $content.moduleTrackingLookup.trackingLookupTitle }}
        </h2>

        <form
          class="order-lookup-tracking__form"
          data-testid="order_lookup_tracking_form"
          @submit.prevent="findOrder"
        >
          <div class="order-lookup-tracking__line">
            <Field
              v-slot="{ field }"
              v-model.trim="emailAddress"
              name="emailAddress"
              rules="required|email"
            >
              <base-input
                v-bind="field"
                class="order-lookup-tracking__input"
                data-testid="order_tracking_input_email"
                required
                autocomplete="off"
                label="Email Address"
              />
            </Field>
          </div>

          <div class="order-lookup-tracking__line">
            <base-input
              v-model="trackingNumber"
              class="order-lookup-tracking__input"
              data-testid="order_tracking_input_tracking_number"
              required
              autocomplete="off"
              label="Tracking number"
            />
          </div>

          <base-button
            class="order-lookup-tracking__submit"
            :activated="active"
            :disabled="!validForm"
          >
            {{ $content.moduleTrackingLookup.trackingLookupTitle }}
          </base-button>
          <div
            v-if="lookUpErrors.length > 0"
            class="order-lookup-tracking__errors"
            role="alert"
          >
            <p>
              {{ $content.moduleTrackingLookupErrors.lookupError }}
            </p>
          </div>
        </form>
      </base-card>
      <tracking-footer />
    </div>
  </main>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { BaseCard, BaseButton, BaseInput, VisuallyHidden } from '@loophq/design-system';
import { Field, useForm, defineRule } from 'vee-validate';
import { email as emailRule } from '@vee-validate/rules';
import NotFound from '@/views/404';
import { featureFlags } from '@/js/constants/featureFlags';
import TrackingFooter from '@/views/ForwardTracking/TrackingFooter.vue';
import BackToShop from '@/components/app/BackToShop.vue';
import trackingApi from '@/js/controllers/tracking';
import { useRouter } from 'vue-router';
import { track } from '@/js/segment';
import { orderTrackingEvents, products } from '@/js/constants/segment';

const store = useStore();
const router = useRouter();

const shop = computed(() => {
  return store.getters.shop;
});

const isTrackingRoute = computed(() => {
  return store.getters.settings.isTracking;
});

const orderTrackingEnabled = computed(() => {
  return store.getters.hasFeature(featureFlags.FORWARD_TRACKING_ENABLED);
});

const emailAddress = ref('');

const trackingNumber = ref('');

const active = ref(false);

const lookUpErrors = ref([]);

const { errors } = useForm();

defineRule('email', emailRule);

const validForm = computed(() => {
  const fieldsHaveValues = emailAddress.value.length && trackingNumber.value.length;

  return fieldsHaveValues && !Object.keys(errors.value).includes('emailAddress');
});

const findOrder = async () => {
  if (active.value === true) {
    return false;
  }

  lookUpErrors.value = [];

  active.value = true;

  try {
    const trackingData = await trackingApi.searchOrderByTrackingNumber({
      shopId: shop.value.id,
      email: emailAddress.value,
      trackingNumber: trackingNumber.value
    });

    track(orderTrackingEvents.ORDER_TRACKING_LOOKUP_SUBMITTED, {
      shop: shop.value.id,
      shopName: shop.value.name,
      email: emailAddress.value,
      trackingNumber: trackingNumber.value,
      product: products.ORDER_TRACKING,
    });

    await router.push(trackingData.url.split('#')[1]);
    window.location.reload();
  } catch (error) {
    const errorMessage = error.response?.data?.message ?? 'search failed';
    lookUpErrors.value.push(errorMessage);
    console.error('Lookup error', errorMessage);
  } finally {
    active.value = false;
  }
};

onMounted(() => {
  track(orderTrackingEvents.TRACKING_PAGE_LOOKUP_VISIT, {
    shop: shop.value.id,
    shopName: shop.value.name,
    product: products.ORDER_TRACKING,
  });
});
</script>

<style lang="scss" scoped>
$block: '.order-lookup-tracking';

#{$block} {
  max-width: 480px;
  width: 100%;
  margin: 0 auto;

  &__form {
    width: 100%;
    max-width: calc(480px - var(--spacing-600) * 2);
    margin: var(--spacing-500) auto 0 auto;
  }

  &__line {
    position: relative;
    margin-bottom: 0.75rem;
  }

  &__submit {
    width: 100%;
    margin-top: 1.25rem;
  }

  &__errors {
    text-align: center;
    padding: 0.5rem;
  }
}

.visibility-hidden {
  visibility: hidden;
}

@media screen and (width <= 680px) {
  #{$block} {
    padding: var(--spacing-400);

    &__content {
      padding: var(--spacing-200);
    }
  }
}

@media screen and (width <= 450px) {
  #{$block} {
    &:deep(.popper__content-wrapper) {
      // 1/4 width of popover less close button offset to center on screen
      margin-left: calc(22vw - 0.65rem);
      transform: translate3d(-90vw, -157px, 0) !important;
    }

    &:deep(.pop-container) {
      // fit popover content on screen
      max-width: 90vw;
    }
  }
}

.tracking-search-container {
  margin-top: 0;
  overflow-x: hidden;
}

.header.order-lookup-tracking-banner {
  margin-top: var(--spacing-600);
}

.back-to-shop-large.back-to-shop-large {
  position: fixed;
  left: var(--spacing-500);
  top: var(--spacing-500);
}
</style>
