<template>
  <page-container
    size="large"
  >
    <base-card class="capture-address">
      <base-text
        class="capture-heading"
        tag="h2"
        type="display-large"
      >
        <render-content>
          {{ $content.moduleMissingAddress.heading }}
        </render-content>
      </base-text>
      <base-text class="capture-copy">
        <render-content>
          {{ $content.moduleMissingAddress.subheading }}
        </render-content>
      </base-text>
      <address-form
        v-model="editing"
        :value="address"
        :available-countries="countryCodes"
        @address-updated="handleNewAddress"
      />
      <div class="capture-actions">
        <base-button
          type="link"
          @click.prevent="backToLookup"
        >
          <render-content>
            {{ $content.moduleMissingInfo.goBack }}
          </render-content>
        </base-button>
        <base-button
          :disabled="!valid || activated"
          :activated="activated"
          @click.prevent="handleSubmit"
        >
          <render-content>
            {{ $content.moduleMissingInfo.submit }}
          </render-content>
        </base-button>
      </div>
    </base-card>
  </page-container>
</template>

<script>
import {
  BaseCard,
  BaseButton,
  BaseText,
} from '@loophq/design-system';
import PageContainer from '@/components/layout/PageContainer';
import AddressForm from '@/components/forms/AddressForm';
import { getCountries } from '@/js/countries';
import Order from '@/js/controllers/order';
const countries = getCountries();

export default {
  components: {
    BaseCard,
    BaseButton,
    BaseText,
    PageContainer,
    AddressForm,
  },
  props: {
    payload: {
      type: Object,
    }
  },
  emits: ['success', 'navigate'],
  data() {
    return {
      address: {
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        country: '',
        zip: '',
        phone: '',
      },
      editing: null,
      activated: false,
    };
  },
  computed: {
    valid() {
      return this.address.address1 && this.address.city && this.address.zip && this.address.country;
    },
    order() {
      return this.$store.getters.order;
    },
    countryCodes() {
      return Object.keys(this.payload.countries);
    }
  },
  methods: {
    handleNewAddress(payload) {
      this.address = payload.address;
      this.invalid = payload.invalid;
    },
    async handleSubmit() {
      const { firstName, lastName, ...rest } = this.address;
      const country = countries.find((country) => country.name === this.address.country);
      const address = {
        ...rest,
        country_code: country.code,
        first_name: firstName,
        last_name: lastName
      };
      this.$store.commit('updateOrder', {
        ...this.order,
        address
      });

      this.activated = true;

      try {
        await Order.updateAddress(this.payload.orderId, address);
        const res = await Order.lookup({
          name: this.payload.name,
          zip: this.payload.zip,
          nonce: '',
        });

        this.handleSuccess(res);
        this.$store.commit('return/setAddress', address);
      } catch (err) {
        this.activated = false;
        this.$error(this.$content.moduleMissingAddress.submitError);
        console.error(err);
      }
    },
    backToLookup() {
      this.$store.commit('updateOrder', 0);
      this.$emit('navigate', { name: 'RegularLookup' });
    },
    handleSuccess(res) {
      if (this.payload.orderEmailMissing) {
        this.$emit('success', {
          action: {
            type: 'loadModule',
            target: 'CaptureEmail',
            meta: {
              fields: [
                'email'
              ]
            }
          },
          data: {
            order: res.data
          }
        });
      } else {
        this.$emit('success', {
          data: {
            order: res.data
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.capture-address {
  * + * {
    margin-top: var(--spacing-400);
  }
}

.capture-heading {
  text-align: center;
}

.capture-copy {
  text-align: center;
  color: var(--grey-700);
}

.capture-form {
  display: flex;
  flex-direction: column;
}

.capture-actions {
  display: flex;
  justify-content: space-between;
}
</style>
