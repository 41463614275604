<template>
  <div>
    <base-text type="body">
      <render-content :data="{ deadline: expiresAt }">
        {{ $content.moduleHappyReturnsDropoff.happyReturnsInstructions }}
      </render-content>
    </base-text>
    <alert-banner
      v-if="showRemovedLocationsAlert"
      class="alert"
      heading="Happy Returns is moving from FedEx Office to The UPS Store"
      description="Your nearest Return Bar locations may have changed and are listed below."
      type="warning"
      :inline="true"
    />
    <div class="dropoff-content">
      <div>
        <div class="qr-code-container">
          <img
            class="happy-returns-logo"
            src="/img/happy-returns-logo.png"
          />
          <img
            class="qr-code"
            :src="returnMethod.qrCodeUrl"
          />
          <base-text class="qr-rmacode">
            {{ returnMethod.rmaCode }}
          </base-text>
        </div>
      </div>
      <div class="dropoff-location-container">
        <happy-returns-locations 
          v-if="showThreeLocations"
          :locations="returnMethod.closest"
        />
        <div v-else>
          <base-text
            v-if="distance?.distance && distance?.unit"
            class="distance"
          >
            <render-content :data="distance">
              {{ $content.moduleHappyReturnsDropoff.closestDropOffCopy }}
            </render-content>
          </base-text>
          <base-text class="address-name">
            {{ returnMethod.address.name }}
          </base-text>
          <address-display :address="displayAddress" />
        </div>
        <div class="dropoff-button-container">
          <base-button
            v-if="!showThreeLocations"
            v-track="'drop off get directions clicked'"
            type="secondary"
            size="small"
            class="dropoff-button"
            target="_blank"
            :to="googleMapsLink"
          >
            <span class="dropoff-button-label">
              {{ $content.moduleHappyReturnsDropoff.directionsButton }}
              <base-icon
                class="icon"
                name="external-link"
              />
            </span>
          </base-button>
          <base-button
            v-track="'drop off view all locations clicked'"
            size="small"
            type="secondary"
            class="dropoff-button"
            target="_blank"
            :to="locationsLink"
          >
            <span class="dropoff-button-label">
              {{ $content.moduleHappyReturnsDropoff.viewAllLocationsButton }}
              <base-icon
                class="icon"
                name="external-link"
              />
            </span>
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BaseText,
  BaseButton,
  BaseIcon,
  AlertBanner,
} from '@loophq/design-system';
import AddressDisplay from '@/components/AddressDisplay';
import HappyReturnsLocations from '../ReviewPage/ReturnMethods/HappyReturnsLocations';
import { featureFlags } from '@/js/constants/featureFlags';

export default {
  components: {
    BaseText,
    AddressDisplay,
    BaseButton,
    BaseIcon,
    AlertBanner,
    HappyReturnsLocations,
  },
  props: {
    returnMethod: {
      type: Object,
      required: true,
    },
    address: {
      type: Object,
      required: true,
    },
    expiresAt: {
      type: String,
      required: true,
    }
  },
  computed: {
    displayAddress() {
      // eslint-disable-next-line
            const { name, ...rest } = this.returnMethod.address;
      return rest;
    },
    locationsLink() {
      return `https://locations.happyreturns.com/?has_qr_code=true&address=${this.address?.zip}`;
    },
    googleMapsLink() {
      return `https://www.google.com/maps/dir/?api=1&destination=${this.returnMethod?.address?.latitude},${this.returnMethod?.address?.longitude}`;
    },
    distance() {
      return {
        distance: this.returnMethod?.distance?.value?.toFixed(2),
        unit: this.returnMethod?.distance?.unit === 'm' ? 'miles' : this.returnMethod?.distance?.unit
      };
    },
    showThreeLocations() {
      return this.$store.getters.hasFeature(featureFlags.SHOW_THREE_HAPPY_RETURNS_LOCATIONS) && this.returnMethod?.closest?.length > 0;
    },
    showRemovedLocationsAlert() {
      return this.$store.getters.hasFeature(featureFlags.HAPPY_RETURNS_REMOVED_LOCATIONS_ALERT_BANNER);
    },
  }
};
</script>

<style lang="scss" scoped>
.qr-code-container {
  border: 1px solid var(--grey-300);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: var(--spacing-500);
}

.qr-code {
  max-width: 180px;
  padding: var(--spacing-200);
  border-radius: 7px;
}

.qr-rmacode {
  padding: var(--spacing-200);
  font-weight: bold;
}

.dropoff-content {
  margin-top: var(--spacing-500);
  display: flex;
  align-items: flex-start;
}

.dropoff-location-container {
  flex: 1;
}

.address-name {
  font-weight: bold;
  margin-top: var(--spacing-200);
}

.distance {
  font-weight: bold;
}

.dropoff-button {
  padding: var(--spacing-200);
  margin-right: var(--spacing-200);
  color: var(--grey-800) !important;
}

.dropoff-button-label {
  font-weight: bold !important;
  display: flex;
  align-items: center;
}

.dropoff-button-container {
  display: flex;
  margin-top: var(--spacing-200);
}

.icon {
  height: 16px;
  margin-left: var(--spacing-100);
}

.happy-returns-logo {
  width: 160px;
}

.alert {
  background: #f1ebd0 !important;
  border: none;
  margin-top: 0.75rem;

  :deep(.base-text) {
    color: var(--heading-color);
    font-size: 14px;
  }
}

@media screen and (max-width: $break-medium) {
  .dropoff-content {
    flex-direction: column;
    align-items: center;
    margin-top: var(--spacing-400);
  }

  .qr-code-container {
    max-width: 180px;
    margin-bottom: var(--spacing-400);
  }

  .distance {
    text-align: center;
  }
}
</style>
