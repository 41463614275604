<template>
  <order-lookup
    class="regular-lookup"
    :class="{ 'expand-to-content': hasGraphics }"
    :criteria="criteria"
    @success="handleSuccess"
    @handle-missing-address="handleMissingAddress"
  >
    <template #subheading>
      <markup-renderer
        v-if="hasSubheading"
        class="subheading"
        :content="$content.moduleOrderLookup.subheading"
      />
      <lookup-graphics
        v-if="hasGraphics"
        class="graphics"
        :content="$content.moduleOrderLookup.graphic.data"
      />
    </template>
    <template #footer>
      <div
        v-if="$settings.giftFlowEnabled"
        class="footer-wrap"
      >
        <base-text
          class="footer-heading"
          tag="h3"
          type="display-small"
        >
          <render-content>
            {{ $content.moduleGiftReturns.ctaCopy }}
          </render-content>
        </base-text>
        <base-button
          class="footer-button"
          :to="{ name: 'gift' }"
          type="secondary"
          size="small"
        >
          <render-content>
            {{ $content.moduleGiftReturns.cta }}
          </render-content>
        </base-button>
      </div>
    </template>
  </order-lookup>
</template>

<script>
import {
  BaseButton,
  BaseText,
} from '@loophq/design-system';
import OrderLookup from '@/modules/OrderLookup';
import MarkupRenderer from '@/components/renderers/MarkupRenderer';
import LookupGraphics from './LookupGraphics';
import { hasContent } from '@/js/helpers/wysiwyg';

export default {
  name: 'RegularLookup',
  components: {
    BaseButton,
    BaseText,
    OrderLookup,
    MarkupRenderer,
    LookupGraphics,
  },
  props: {
    criteria: {
      type: Object,
      required: false,
      validator: (val) => {
        return val && 'order' in val;
      }
    }
  },
  emits: [
    'success',
    'handleMissingAddress',
  ],
  computed: {
    hasSubheading() {
      return hasContent(this.$content.moduleOrderLookup.subheading) &&
        this.$content.moduleOrderLookup.subheadingType === 'subheading';
    },
    hasGraphics() {
      return this.$content.moduleOrderLookup.subheadingType === 'graphic';
    },
  },
  methods: {
    handleSuccess(data) {
      this.$emit('success', data);
    },
    handleMissingAddress(payload) {
      this.$emit('handleMissingAddress', payload);
    }
  }
};
</script>

<style lang="scss" scoped>
.regular-lookup {
  &.expand-to-content {
    max-width: none;
    min-width: 480px;
    width: min-content;
  }
}

.subheading {
  margin-top: 0.5rem;
  text-align: center;
}

.graphics {
  align-self: center;
  margin: var(--spacing-500) 0;
}

.footer-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: var(--spacing-400);
}

.footer-heading {
  font-size: 1.0625rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: $break-small) {
  .regular-lookup {
    &.expand-to-content {
      width: auto;
      min-width: 0;
    }
  }

  .graphics {
    align-self: center;
    margin-top: var(--spacing-300);
    margin-bottom: calc(var(--spacing-300) * -1);
  }
}
</style>
