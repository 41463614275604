<template>
  <div
    class="customer-info"
    data-testid="customer-info"
  >
    <base-button
      v-if="canEdit"
      class="customer-info__edit"
      type="link"
      @click.prevent="active = true"
    >
      <render-content>
        {{ $content.moduleCustomerInfo.edit }}
      </render-content>
    </base-button>

    <div class="customer-info__rows">
      <div class="customer-info__row">
        <base-text
          class="customer-info__row-heading"
          tag="h3"
          type="heading"
        >
          <render-content>
            {{ $content.moduleCustomerInfo.infoHeading }}
          </render-content>
        </base-text>
        <base-text
          v-if="customer.email"
          class="customer-info__row-line"
        >
          {{ customer.email }}
        </base-text>
        <base-text
          v-if="address.phone"
          class="customer-info__row-line"
        >
          {{ address.phone }}
        </base-text>
      </div>
      <div class="customer-info__row">
        <base-text
          class="customer-info__row-heading"
          tag="h3"
          type="heading"
        >
          <render-content>
            {{ $content.moduleCustomerInfo.addressHeading }}
          </render-content>
        </base-text>
        <base-text class="customer-info__row-line">
          {{ displayAddress }}
        </base-text>
      </div>
    </div>
    <modal-dialog
      :show="isActive"
      :heading="$content.moduleCustomerInfo.updateHeading"
      @close="handleClose"
    >
      <base-text
        class="customer-info__section-heading"
        tag="h2"
        type="display-small"
      >
        <render-content>
          {{ $content.moduleCustomerInfo.updateShipping }}
        </render-content>
      </base-text>
      <address-form
        v-model="editing"
        :value="editing"
        :errors="errors"
        class="customer-info__address"
        :disabled-fields="['name', 'country']"
        :available-countries="[address.countryCode]"
      />
      <base-text
        class="customer-info__section-heading"
        tag="h2"
        type="display-small"
      >
        <render-content>
          {{ $content.moduleCustomerInfo.updateContact }}
        </render-content>
      </base-text>
      <div class="customer-info__info">
        <Field
          v-slot="{ errors: validationErrors, field }"
          v-model="email"
          name="email"
          rules="required|email"
          immediate
          slim
        >
          <base-input
            :model-value="email"
            v-bind="field"
            :label="$content.moduleAddressForm.email"
            :error="validationErrors[0] ?? (errors && errors[field.name]) ?? null"
            :disabled="true"
          />
        </Field>
        <Field
          v-slot="{ field }"
          v-model="phone"
          name="phone"
          slim
        >
          <base-input
            model-value="phone"
            v-bind="field"
            :label="$content.moduleAddressForm.phone"
            :error="(errors && errors[field.name]) ?? null"
            :disabled="true"
          />
        </Field>
      </div>
      <template #footer="{ meta }">
        <base-button
          type="secondary"
          class="customer-info__cancel"
          :disabled="state === 'saving'"
          @click.prevent="cancel"
        >
          <render-content>
            {{ $content.moduleCustomerInfo.cancelCta }}
          </render-content>
        </base-button>
        <base-button
          data-testid="save-button"
          class="customer-info__save"
          :disabled="!meta.valid || !meta.dirty"
          :activated="state === 'saving'"
          @click.prevent="save"
        >
          <render-content>
            {{ $content.moduleCustomerInfo.saveCta }}
          </render-content>
        </base-button>
      </template>
    </modal-dialog>
  </div>
</template>

<script>
import {
  BaseText,
  BaseButton,
  BaseInput,
} from '@loophq/design-system';
import ModalDialog from '@/components/layout/Modal';
import AddressForm from '@/components/forms/AddressForm';
import Return from '@/js/controllers/return';
import { stateAbbreviations } from '@/js/constants/stateAbbreviations';
export default {
  components: {
    BaseText,
    BaseButton,
    BaseInput,
    ModalDialog,
    AddressForm
  },
  props: {
    customer: {
      type: Object,
      required: true
    },
    address: {
      type: Object,
      required: true
    },
    returnId: {
      type: [String, Number],
      required: false
    },
    canEdit: {
      type: Boolean,
      required: false,
      default: false
    },
    isModalActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    errors: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  emits: [
    'close',
    'update',
  ],
  data() {
    return {
      active: false,
      state: null,
      email: null,
      phone: null,
      editing: null
    };
  },
  computed: {
    displayAddress() {
      const country = this.address.country;

      return this.addressLine + this.cityStateZip + country;
    },
    addressLine() {
      const { address1, address2 } = this.address;

      return address1 + (address2 ? ' ' + address2 : '') + ' ';
    },
    cityStateZip() {
      const { zip, city } = this.address;

      if (this.isSwissAddress) {
        return `${zip} ${city}${this.displayState} `;
      } else {
        return `${city}${this.displayState} ${zip} `;
      }
    },
    displayState() {
      let addressState = stateAbbreviations[this.address.state] ?? this.address.state;

      return addressState ? ` ${addressState}`: '';
    },
    isActive() {
      return this.active || this.isModalActive;
    },
    isSwissAddress() {
      return this.address.country === 'Switzerland';
    },
    shouldNotCallApi() {
      return this.returnId == null;
    }
  },
  created() {
    const { phone, ...address } = this.address;
    this.email = this.customer.email;
    this.phone = phone;
    this.editing = address;
  },
  methods: {
    cancel() {
      this.active = false;
      this.$emit('close');
    },
    setNewCustomerAddress() {
      const { address1, address2, city, state, zip, country, countryCode } = this.editing;
      const address = {
        address1,
        address2,
        city,
        state,
        zip,
        country,
        countryCode,
        phone: this.phone,
      };

      this.$store.commit('return/setAddress', address);
      this.$store.dispatch('firstMile/getEligibility');
      
      return address;
    },
    async save() {
      this.state = 'saving';

      try {
        // if we are updating the address before the return is created,
        // we need to update the store instead of calling the api
        const updatedAddress = this.shouldNotCallApi
          ? this.setNewCustomerAddress()
          : await Return.updateCustomerAddress(this.returnId, this.editing);

        this.$emit('update', { address: updatedAddress });
        this.$toast(this.$content.moduleCustomerInfo.updateSuccess);
        this.state = null;
        this.active = false;
        this.$emit('close');
      } catch (error) {
        console.error(error);
        this.state = null;
        this.$error(this.$content.moduleCustomerInfo.updateError);
      }
    },
    handleClose() {
      this.active = false;
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.customer-info';
#{$block} {
  &__edit {
    float: right;
  }

  &__rows {
    display: flex;
    flex-direction: column;
  }

  &__row {
    & + & {
      margin-top: var(--spacing-300);
    }
  }

  &__row-heading {
    font-weight: 600;
    margin-bottom: var(--spacing-200);
  }

  &__section-heading {
    margin: var(--spacing-200) 0 0 0;
  }

  &__address {
    margin: var(--spacing-300) 0;
  }

  &__info {
    margin: var(--spacing-300) 0 0 0;

    > * + * {
      margin-top: var(--spacing-300);
    }
  }

  &__cancel {
    margin-left: auto;
  }

  &__save {
    margin-left: var(--spacing-300);
  }
}
</style>
