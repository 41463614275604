<template>
  <transition
    name="card"
    appear
  >
    <base-card
      v-if="!automaticLookupActive"
      class="order-lookup"
    >
      <h2 class="order-lookup__heading">
        <render-content>
          {{ $content[contentModule].heading }}
        </render-content>
      </h2>

      <slot
        v-if="$slots.subheading"
        name="subheading"
      ></slot>
      <form
        class="order-lookup__form"
        @submit.prevent="findOrder"
      >
        <div class="order-lookup__line">
          <base-input
            v-model.trim="orderNumber"
            class="order-lookup__order-number"
            required
            autocomplete="off"
            action-placement="before"
            :label="$content.moduleOrderLookup.orderPlaceholder"
          >
            <template #action>
              <popup
                v-if="tooltip"
                class="order-lookup__tip-wrapper"
                :active="tooltipActive"
                :data="tooltip"
                name="lookup-help-tip"
                @update="tooltipActive = false"
              >
                <button
                  id="lookup-help-tip"
                  type="button"
                  class="order-lookup__tip"
                  :aria-expanded="tooltipActive.toString()"
                  @click="tooltipActive = !tooltipActive"
                >
                  <span class="visually-hidden">Open help tooltip</span>
                  <svg
                    class="order-lookup__tip-icon"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      d="M10.04.162C4.552.162.082 4.586.082 10.02c0 5.434 4.47 9.858 9.96 9.858 5.49 0 9.959-4.424 9.959-9.858C20 4.586 15.531.16 10.04.16zm0 18.222c-4.653 0-8.448-3.758-8.448-8.363 0-4.607 3.796-8.364 8.449-8.364 4.653 0 8.448 3.758 8.448 8.364-.02 4.605-3.795 8.363-8.448 8.363zm0-4.687c-.55 0-.98.444-.98.97s.45.97.98.97c.532 0 .98-.444.98-.97a.988.988 0 0 0-.98-.97zm0-9.111c-1.632 0-2.98 1.313-2.98 2.95a.75.75 0 0 0 .756.747.763.763 0 0 0 .754-.747c0-.788.653-1.435 1.45-1.435.837 0 1.45.585 1.45 1.374v.06c0 .445-.124 1.495-1.572 1.718a.763.763 0 0 0-.653.747v2.04a.75.75 0 0 0 .755.748.763.763 0 0 0 .755-.747v-1.435c1.918-.585 2.224-2.222 2.224-3.131.041-1.616-1.306-2.89-2.938-2.89z"
                      fill-rule="nonzero"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </popup>
            </template>
          </base-input>
        </div>

        <div class="order-lookup__line">
          <base-input
            v-model.trim="zip"
            class="order-lookup__zip"
            required
            autocomplete="off"
            :label="label"
          />
        </div>

        <base-button
          class="order-lookup__submit"
          :disabled="!valid"
          :activated="active"
        >
          <render-content>
            {{ $content[contentModule].submit }}
          </render-content>
        </base-button>

        <div
          v-if="errors.length"
          class="order-lookup__errors"
          role="alert"
        >
          <template v-for="error in errors">
            <p
              v-if="$content.moduleLookupErrors[error]"
              :key="error"
            >
              <render-content>
                {{ $content.moduleLookupErrors[error] }}
              </render-content>
            </p>
          </template>
        </div>
      </form>

      <slot
        v-if="$slots.footer"
        name="footer"
      ></slot>
    </base-card>
    <base-card
      v-else
      class="order-lookup order-lookup__automatic"
    >
      <h2 class="order-lookup__heading">
        <render-content :data="{ orderName: orderNumber }">
          {{ $content[contentModule].automaticHeading }}
        </render-content>
      </h2>
      <base-loader
        class="order-lookup__loader"
        size="large"
      />
    </base-card>
  </transition>
</template>

<script>
import {
  BaseCard,
  BaseInput,
  BaseButton,
  BaseLoader,
} from '@loophq/design-system';
import Order from '@/js/controllers/order';
import { track, identify } from '@/js/segment';
import useStoreMethods from '@/js/mixins/store';
import changeCase from 'change-object-case';
import { products } from '@/js/constants/segment';

const { setStore } = useStoreMethods();

export default {
  name: 'OrderLookup',
  components: {
    BaseCard,
    BaseButton,
    BaseInput,
    BaseLoader,
  },
  props: {
    contentModule: {
      type: String,
      required: false,
      default: 'moduleOrderLookup'
    },
    criteria: {
      type: Object,
      required: false,
      validator: (val) => 'order' in val || true
    }
  },
  emits: [
    'success',
    'handleMissingAddress',
  ],
  data() {
    return {
      orderNumber: '',
      zip: '',
      active: false,
      tooltipActive: false,
      errors: [],
      automaticLookupActive: false
    };
  },
  computed: {
    valid() {
      const validWithNonce = this.nonce.length && this.orderNumber.length;
      const validWithZip = this.zip.length && this.orderNumber.length;

      return validWithNonce || validWithZip;
    },
    tooltip() {
      // Hide tooltip if there are no values in customizations
      if (
        !this.$content.moduleLookupTooltip.copy &&
        !this.$content.moduleLookupTooltip.heading
      ) {
        return false;
      }

      return {
        copy: this.$content.moduleLookupTooltip.copy,
        email: this.$shop.support_email,
        link__header: this.$content.moduleLookupTooltip.heading
      };
    },
    language() {
      return this.$store.state.language || 'en';
    },
    customLookupPlaceholder() {
      return this.$store.state.customizations.content?.[this.language]?.moduleOrderLookup?.zipPlaceholder || null;
    },
    label() {
      return this.customLookupPlaceholder ?? this.buildLookupPlaceholder();
    }
  },
  created() {
    // Prefill the fields based on existing criteria
    this.orderNumber = this.criteria?.order ?? '';
    this.zip = this.criteria?.zip ?? this.criteria?.email ?? this.criteria?.phone ?? '';
    this.nonce = this.criteria?.nonce ?? '';

    // Perform the lookup automatically if we have lookup criteria set (i.e. from a deep link)
    if (this.valid) {
      // if this is a gift receipt order being looked up, we will already have order info
      // hydrated into state from the DeepLink module (emit 'success' to go to next step).
      if (this.criteria?.is_gift) {
        this.$emit('success', {
          data: {
            order: this.$store.state.order
          }
        });
      } else {
        this.automaticLookupActive = true;
        this.findOrder();
      }
    }
  },
  methods: {
    buildLookupPlaceholder() {
      const labels = {
        email: this.$content.moduleOrderLookup.emailPlaceholder || 'Email',
        phone: this.$content.moduleOrderLookup.phonePlaceholder || 'Phone Number',
        zip: this.$content.moduleOrderLookup.zipPlaceholder || 'Shipping Zip Code'
      };

      // build placeholder based on available lookup fields in shop settings (e.g: zip,email,phone)
      return (this.$settings.availableLookupFields || 'zip')
        .split(',')
        .map(field => labels[field])
        .join(' or ');
    },
    async findOrder() {
      this.errors = [];

      // Bail out if they somehow resubmit
      if (this.active) {
        return false;
      }

      // Update UI to show loader on button
      this.active = true;

      try {
        const payload = {
          name: this.orderNumber,
          zip: this.zip,
          nonce: this.nonce,
        };
        const res = await Order.lookup(payload);

        if (res.data.errors) {
          this.automaticLookupActive = false;
          if (res.data.errors.includes('errorCode9')) {
            payload.orderId = res.data.orderId;
            payload.countries = res.data.countries;
            payload.orderEmailMissing = res.data.orderEmailMissing;
            this.$emit('handleMissingAddress', payload);
          }

          if (res.data.errors.length) {
            this.errors = res.data.errors;
          }

          this.$trackEvent('failed lookup');
        } else {
          const { customer, provider_order_id, platform_credits, experiments } = res.data;
          const { identifier, session } = customer;

          if (identifier) {
            identify(identifier);
          }

          const analytics = {
            customerIdentifier: identifier,
            session,
            order: provider_order_id,
            shop: this.$store.state.shop_id,
            shopName: this.$shop.name,
            product: products.CUSTOMER_PORTAL,
          };

          this.$store.commit('analytics/setData', { ...analytics });
          // preserve analytics across page refreshes
          setStore('analytics', analytics);
          track('orderLookupSubmitted', {
            ...analytics
          });

          if (platform_credits && Object.keys(platform_credits).length) {
            const platformCredits = changeCase.toCamel(platform_credits);
            track('eligibleForPlatformCredit', {
              ...analytics,
              platformCredits
            });
          }

          if (experiments && Object.keys(experiments).length) {
            track('returnsPortalExperiments', {
              ...analytics,
              experiments: JSON.stringify(experiments)
            });
          }

          this.errors = [];

          // Check to make sure there is an email
          if (!customer.hasEmail) {
            this.$emit('success', {
              action: {
                type: 'loadModule',
                target: 'CaptureEmail',
                meta: {
                  fields: [
                    'email'
                  ]
                }
              },
              data: {
                order: res.data
              }
            });
          } else if (!customer.hasAddress) {
            // I don't believe this is possible anymore,
            // in-store purchases through POS that are returned online
            // do not have an address that can be used for the return policy,
            // so we always prompt for the address based on errorCode9
            payload.orderId = res.data.id;
            payload.countries = res.data.countries;
            this.$emit('handleMissingAddress', payload);
          } else {
            this.$emit('success', {
              data: {
                order: res.data
              }
            });
          }
        }

        this.active = false;

      } catch (error) {
        console.log('Error: ', error);
        console.trace(error);

        if (error.response) {
          console.error(`OrderLookup#findOrder failed looking up order, status: ${error.response.status}, data: ${error.response.data}`);
          // we at least got a response from server
        } else if (error.request) {
          // we made a request but got no response
          console.error(`OrderLookup#findOrder failed looking up order, no response from server`);
        } else if (error.message) {
          // we couldn't even make a request, there was a normal JS error
          console.error(`OrderLookup#findOrder failed looking up order, err: ${error.message}`);
        } else {
          console.error(`OrderLookup#findOrder failed looking up order, err: ${error}`);
        }

        if (error.response?.data?.errors) {
          this.errors = error.response.data.errors;
        } else {
          this.errors = ['errorCode4'];
        }

        this.active = false;
        this.automaticLookupActive = false;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
$block: '.order-lookup';

#{$block} {
  max-width: 480px;
  width: 100%;

  &__heading {
    margin: 0.5rem 0 0;
    font-size: 1.625rem;
  }

  &__form {
    // When the lookup graphics flex the container wider, we want to constrain the form width
    max-width: calc(480px - (var(--spacing-600) * 2));
    width: 100%;
    margin: var(--spacing-500) auto 0 auto;
  }

  &__line {
    position: relative;
    margin-bottom: 0.75rem;
  }

  &__tip-wrapper {
    right: -0.5rem;
    pointer-events: all;
  }

  &__tip {
    position: relative;
    appearance: none;
    background: transparent;
    border: 0;
    padding: var(--spacing-200);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--grey-600);
    border-radius: var(--corners);
    transition: background-color var(--easing-300), color var(--easing-300);
    pointer-events: all !important;

    // Larger tap target
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 2.75rem;
      height: 2.75rem;
      transform: translate3d(-50%, -50%, 0);
    }

    &:hover {
      background: var(--grey-200);
      cursor: pointer;
    }

    &.focus-visible {
      outline: none;
      background: var(--primary-color);
      color: var(--primary-text-color);
    }
  }

  &__tip-icon {
    width: 1.25rem;
  }

  &__input {
    appearance: none;
    font-size: 1rem;
    padding: 1rem;

    &:focus {
      outline: none;
    }

    &.has-tip {
      padding-right: 2.75rem;
    }
  }

  &__submit {
    width: 100%;
    margin-top: 1.25rem;
  }

  &__errors {
    text-align: center;
    padding: 0.5rem;
  }

  &__automatic {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__loader {
    margin-top: var(--spacing-400);
  }
}

@media screen and (width <= 680px) {
  #{$block} {
    padding: var(--spacing-400);

    &__content {
      padding: var(--spacing-200);
    }
  }
}

@media screen and (width <= 450px) {
  #{$block} {
    &:deep(.popper__content-wrapper) {
      // 1/4 width of popover less close button offset to center on screen
      margin-left: calc(22vw - 0.65rem);
      transform: translate3d(-90vw, -157px, 0) !important;
    }

    &:deep(.pop-container) {
      // fit popover content on screen
      max-width: 90vw;
    }
  }
}
</style>
