export const orderTrackingEvents = {
  TRACKING_PAGE_LOOKUP_VISIT: 'trackingLookupPageVisit',
  TRACKING_PAGE_VISIT: 'trackingPageVisit',
  ORDER_TRACKING_LOOKUP_SUBMITTED: 'orderTrackingLookupSubmitted',
  RECOMMENDED_PRODUCT_CLICKED: 'reccProductClicked',
  MORE_PRODUCTS_CLICKED: 'moreProductsClicked',
  START_RETURN_CLICKED: 'startReturnClicked',
};

export const customerPortalEvents = {
  SUPPORT_EMAIL_CLICKED: 'supportEmailClicked',
  ELIGIBLE_ITEM_COUNT: 'eligibleItemCount',
};

export const products = {
  CUSTOMER_PORTAL: 'customer-portal',
  ORDER_TRACKING: 'order-tracking',
};

export const customerPortalPageNames = {
  ORDER_LOOKUP_PAGE: 'Start Page',
  EXCHANGE_PAGE: 'Item Selection Page',
  CREDIT_PAGE: 'Return or Exchange Options',
  REVIEW_PAGE: 'Summary Page',
  STATUS_PAGE: 'Confirmation Page',
};
