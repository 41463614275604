/**
 * Replaces mustache variables in a string
 * @example
 * // Returns "Replace this text"
 * formatCustomizationString('Replace {{ variable }} text', { variable: 'this' })
 * @param {string} text - The text with variables in it
 * @param {object} map - A map object where the key is the variable name and the value is the value to set
 * @returns {string}
 */
export const formatCustomizationString = (text, map) => {
  return text.replace(/({{\s*)(\w+|-)+(\s*}})/g, (match, g1, g2) => {
    return map[g2] ?? match;
  });
};
